// extracted by mini-css-extract-plugin
export var buttons = "bJ_b6";
export var left = "bJ_bL";
export var media = "bJ_g3";
export var right = "bJ_bQ";
export var section = "bJ_j";
export var selected = "bJ_bg";
export var signature = "bJ_g2";
export var testimonial = "bJ_g1";
export var text = "bJ_b3";
export var textContainer = "bJ_bV";
export var video = "bJ_fT";