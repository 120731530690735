// extracted by mini-css-extract-plugin
export var bottomContainer = "w_b5";
export var buttons = "w_b6";
export var icon = "w_bw";
export var link = "w_bt";
export var section = "w_j";
export var social = "w_b4";
export var text = "w_b3";
export var title = "w_g";
export var topContainer = "w_b2";
export var videoContainer = "w_b0";
export var videoWrapper = "w_b1";