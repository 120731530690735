// extracted by mini-css-extract-plugin
export var active = "bc_bd";
export var arrow = "bc_9";
export var border = "bc_dy";
export var button = "bc_cr";
export var column = "bc_cv";
export var columns = "bc_ct";
export var container = "bc_b";
export var description = "bc_dt";
export var dot = "bc_dD";
export var footer = "bc_ck";
export var footerText = "bc_cl";
export var heading = "bc_G";
export var icon = "bc_bw";
export var includes = "bc_dB";
export var info = "bc_cZ";
export var left = "bc_bL";
export var mobileOption = "bc_dC";
export var option = "bc_dw";
export var optionText = "bc_dx";
export var options = "bc_dv";
export var padding = "bc_dK";
export var plans = "bc_dz";
export var popular = "bc_dJ";
export var price = "bc_dF";
export var right = "bc_bQ";
export var section = "bc_j";
export var show = "bc_L";
export var subHeading = "bc_H";
export var subText = "bc_dH";
export var suffix = "bc_dG";
export var title = "bc_g";
export var waveOne = "bc_q";
export var waveThree = "bc_b8";
export var waveTwo = "bc_n";
export var waves = "bc_b7";