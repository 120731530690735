// extracted by mini-css-extract-plugin
export var bottom = "bv_s";
export var contact = "bv_fD";
export var faqCat = "bv_fM";
export var faqCatDescription = "bv_fQ";
export var faqCatTitle = "bv_fP";
export var faqCategories = "bv_fL";
export var faqIcon = "bv_fN";
export var footerText = "bv_cl";
export var icon = "bv_bw";
export var iconContainer = "bv_bX";
export var iconText = "bv_fG";
export var immediate = "bv_fF";
export var inputContainer = "bv_c6";
export var linksSubtitle = "bv_fJ";
export var linksTitle = "bv_fH";
export var search = "bv_cF";
export var searchInput = "bv_c7";
export var section = "bv_j";
export var selected = "bv_bg";
export var supportButton = "bv_fR";
export var supportLinks = "bv_fK";
export var top = "bv_l";
export var wave = "bv_fC";