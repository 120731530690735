// extracted by mini-css-extract-plugin
export var active = "x_bd";
export var activeTargetContainer = "x_cc";
export var container = "x_b";
export var content = "x_bZ";
export var contentButton = "x_cj";
export var feature = "x_cg";
export var featureContainer = "x_cf";
export var featureText = "x_ch";
export var features = "x_cd";
export var footer = "x_ck";
export var footerText = "x_cl";
export var icon = "x_bw";
export var outerFeaturesContainer = "x_cb";
export var section = "x_j";
export var text = "x_b3";
export var title = "x_g";
export var topButton = "x_b9";
export var waveOne = "x_q";
export var waveThree = "x_b8";
export var waveTwo = "x_n";
export var waves = "x_b7";