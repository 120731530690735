// extracted by mini-css-extract-plugin
export var body = "bz_c";
export var buttons = "bz_b6";
export var container = "bz_b";
export var icon = "bz_bw";
export var icons = "bz_bs";
export var image = "bz_k";
export var items = "bz_gG";
export var label = "bz_cR";
export var mobileButtons = "bz_gH";
export var name = "bz_gF";
export var section = "bz_j";
export var selected = "bz_bg";
export var show = "bz_L";
export var title = "bz_g";