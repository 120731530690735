// extracted by mini-css-extract-plugin
export var bottomCircle = "bK_g5";
export var content = "bK_bZ";
export var description = "bK_dt";
export var hide = "bK_C";
export var icon = "bK_bw";
export var image = "bK_k";
export var imageContainer = "bK_g9";
export var left = "bK_bL";
export var nextYear = "bK_g8";
export var right = "bK_bQ";
export var section = "bK_j";
export var shrink = "bK_g7";
export var title = "bK_g";
export var topCircle = "bK_g4";
export var wave = "bK_fC";
export var waveContainer = "bK_d0";
export var year = "bK_g6";