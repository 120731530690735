// extracted by mini-css-extract-plugin
export var appStores = "X_dr";
export var grey = "X_ds";
export var icon = "X_bw";
export var iconGroup = "X_dq";
export var image = "X_k";
export var left = "X_bL";
export var reverse = "X_dp";
export var right = "X_bQ";
export var section = "X_j";
export var textSection = "X_dn";