// extracted by mini-css-extract-plugin
export var container = "Y_b";
export var content = "Y_bZ";
export var icon = "Y_bw";
export var iconContainer = "Y_bX";
export var icons = "Y_bs";
export var image = "Y_k";
export var label = "Y_cR";
export var section = "Y_j";
export var text = "Y_b3";
export var title = "Y_g";