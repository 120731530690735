// extracted by mini-css-extract-plugin
export var arrow = "bl_9";
export var body = "bl_c";
export var circle = "bl_fp";
export var customer = "bl_fn";
export var feedback = "bl_fm";
export var feedbacks = "bl_fk";
export var footer = "bl_ck";
export var footerText = "bl_cl";
export var left = "bl_bL";
export var nameLocation = "bl_fq";
export var padding = "bl_dK";
export var right = "bl_bQ";
export var section = "bl_j";
export var selected = "bl_bg";
export var selectors = "bl_fj";
export var subtitle = "bl_cP";
export var text = "bl_b3";
export var title = "bl_g";
export var wrapper = "bl_fl";