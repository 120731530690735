// extracted by mini-css-extract-plugin
export var background = "bt_z";
export var backgroundContainer = "bt_fw";
export var bottomImage = "bt_t";
export var bottomImageContainer = "bt_fB";
export var buttons = "bt_b6";
export var container = "bt_b";
export var imagesContainer = "bt_fz";
export var middleWave = "bt_dd";
export var section = "bt_j";
export var text = "bt_b3";
export var textContainer = "bt_bV";
export var title = "bt_g";
export var topImage = "bt_m";
export var waveOverlay = "bt_fy";