// extracted by mini-css-extract-plugin
export var bandwidth = "Q_cY";
export var bandwidthIcon = "Q_cX";
export var button = "Q_cr";
export var buttonContainer = "Q_N";
export var buttons = "Q_b6";
export var color = "Q_bk";
export var colorContainer = "Q_bj";
export var container = "Q_b";
export var disclaimer = "Q_c0";
export var footerSubtitle = "Q_c2";
export var footerTitle = "Q_c1";
export var group = "Q_cQ";
export var hide = "Q_C";
export var icon = "Q_bw";
export var info = "Q_cZ";
export var label = "Q_cR";
export var left = "Q_bL";
export var no = "Q_cW";
export var number = "Q_cy";
export var numbers = "Q_cx";
export var numbersContainer = "Q_cS";
export var right = "Q_bQ";
export var section = "Q_j";
export var selected = "Q_bg";
export var show = "Q_L";
export var slideContainer = "Q_bh";
export var slider = "Q_bl";
export var subtitle = "Q_cP";
export var text = "Q_b3";
export var title = "Q_g";
export var yes = "Q_cV";
export var yesNoText = "Q_cT";