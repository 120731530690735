// extracted by mini-css-extract-plugin
export var container = "bB_b";
export var feature = "bB_cg";
export var featureText = "bB_ch";
export var features = "bB_cd";
export var footer = "bB_ck";
export var footerText = "bB_cl";
export var icon = "bB_bw";
export var section = "bB_j";
export var text = "bB_b3";
export var title = "bB_g";
export var waveOne = "bB_q";
export var waveThree = "bB_b8";
export var waveTwo = "bB_n";
export var waves = "bB_b7";