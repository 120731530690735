// extracted by mini-css-extract-plugin
export var answer = "bC_gQ";
export var arrow = "bC_9";
export var body = "bC_c";
export var category = "bC_c5";
export var contactIcons = "bC_gJ";
export var current = "bC_ft";
export var fade = "bC_gM";
export var footer = "bC_ck";
export var footerText = "bC_cl";
export var icon = "bC_bw";
export var iconContainer = "bC_bX";
export var issue = "bC_gP";
export var issueContainer = "bC_gL";
export var issueText = "bC_gN";
export var issues = "bC_gK";
export var left = "bC_bL";
export var padding = "bC_dK";
export var right = "bC_bQ";
export var section = "bC_j";
export var selectors = "bC_fj";
export var subtitle = "bC_cP";
export var title = "bC_g";