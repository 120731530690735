// extracted by mini-css-extract-plugin
export var bottomWave = "bs_df";
export var button = "bs_cr";
export var buttonContainer = "bs_N";
export var buttonFooter = "bs_fx";
export var container = "bs_b";
export var middleWave = "bs_dd";
export var section = "bs_j";
export var subtitle = "bs_cP";
export var textContainer = "bs_bV";
export var title = "bs_g";