// extracted by mini-css-extract-plugin
export var buttons = "bk_b6";
export var container = "bk_b";
export var image = "bk_k";
export var innerText = "bk_fd";
export var logo = "bk_bn";
export var p = "bk_ff";
export var section = "bk_j";
export var short = "bk_fg";
export var tall = "bk_fh";
export var textContainer = "bk_bV";