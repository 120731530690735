// extracted by mini-css-extract-plugin
export var active = "bw_bd";
export var categories = "bw_c4";
export var category = "bw_c5";
export var description = "bw_dt";
export var featured = "bw_c8";
export var icon = "bw_bw";
export var input = "bw_cG";
export var mobileVidContainer = "bw_gb";
export var mobileVideo = "bw_gc";
export var mobileVideoThumbnail = "bw_gd";
export var mobileVideoTitle = "bw_gf";
export var mobileVidsContainer = "bw_f9";
export var moreVideo = "bw_f5";
export var moreVideoContainer = "bw_f4";
export var moreVideoThumbnail = "bw_f6";
export var moreVideoTitle = "bw_f7";
export var moreVideos = "bw_fX";
export var moreVideosHeader = "bw_fW";
export var moreVideosSlider = "bw_f3";
export var moreVideosSliderMobile = "bw_f2";
export var scrollArrow = "bw_fZ";
export var scrollButton = "bw_fY";
export var scrollLeft = "bw_f0";
export var scrollRight = "bw_f1";
export var search = "bw_cF";
export var section = "bw_j";
export var sectionTitle = "bw_fV";
export var selector = "bw_f8";
export var selectors = "bw_fj";
export var video = "bw_fT";
export var videoContainer = "bw_fS";