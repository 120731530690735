// extracted by mini-css-extract-plugin
export var buttons = "P_b6";
export var column = "P_cv";
export var columns = "P_ct";
export var container = "P_b";
export var down = "P_cK";
export var download = "P_cJ";
export var footer = "P_ck";
export var information = "P_cH";
export var section = "P_j";
export var show = "P_L";
export var text = "P_b3";
export var title = "P_g";
export var units = "P_cL";
export var up = "P_cN";
export var upload = "P_cM";
export var waveOne = "P_q";
export var waveThree = "P_b8";
export var waveTwo = "P_n";
export var waves = "P_b7";