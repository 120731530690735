// extracted by mini-css-extract-plugin
export var caption = "bD_gR";
export var grey = "bD_ds";
export var heading = "bD_G";
export var image = "bD_k";
export var left = "bD_bL";
export var reverse = "bD_dp";
export var right = "bD_bQ";
export var section = "bD_j";
export var video = "bD_fT";
export var videoContainer = "bD_b0";
export var videoWrapper = "bD_b1";