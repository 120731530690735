// extracted by mini-css-extract-plugin
export var active = "bG_bd";
export var categories = "bG_c4";
export var category = "bG_c5";
export var icon = "bG_bw";
export var icons = "bG_bs";
export var image = "bG_k";
export var member = "bG_gW";
export var memberTitle = "bG_gX";
export var name = "bG_gF";
export var placeholder = "bG_bb";
export var section = "bG_j";
export var show = "bG_L";
export var subtitle = "bG_cP";
export var team = "bG_gV";