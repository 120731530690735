import React, { useState, useRef, useEffect, useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './animatedTechProtect.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Wave from 'react-wavify';
import { Context } from '../../../context'
import ReactMarkdown from 'react-markdown';

export default ({ block }) => {
  const [pause, setPause] = useState(true);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 30 : 30)
  const context = useContext(Context);
  const ref = useRef<Element>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const activeTargetRef = useRef<HTMLDivElement>(null)

  const [activeIndex, setActiveIndex] = useState(null);
  const [previousActiveIndex, setPreviousActiveIndex] = useState(null);

  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setAmplitude(window.innerWidth / 30)
      }, 1000)
    }
    changeWidth()
  }, [context])
  
  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
  }, [context])

  const activateIndex = (index) => {
    setPreviousActiveIndex(activeIndex);
    setActiveIndex(index);
  }

  useEffect(() => {
    if (!containerRef.current || typeof window === 'undefined' || !activeTargetRef.current) {
      return;
    }

    const targetRect = activeTargetRef.current!.getBoundingClientRect()
    const targetLeft = targetRect.left
    const targetTop = targetRect.top

    let activeItem

    Array.from(containerRef.current.children[1].children).forEach((c,i) => {
      const item = c.children[0] as HTMLDivElement
      if (i === activeIndex) {
        activeItem = item
        let tempTop
        let offsetHeight
        c.classList.add(styles.active)
        if (window.innerWidth <= 480) {
          item.style.transition = 'none'
          Array.from(item.children).forEach(i => {
            i.style.transition = 'none'
            i.classList.add(styles.active)
          })
          item.classList.add(styles.active)
          offsetHeight = item.offsetHeight
          containerRef.current.style.transition = 'none'
          containerRef.current!.style.gridTemplateRows = `${item.offsetHeight + 'px'} 1fr`
          containerRef.current.style.transition = 'all 0.5s ease-in-out'
          tempTop = item.getBoundingClientRect().top
          item.classList.remove(styles.active)
          item.style.transition = 'all 0.5s ease-in-out'
          Array.from(item.children).forEach(i => {
            i.style.transition = 'all 0.5s ease-in-out'
            i.classList.remove(styles.active)
          })
        }
        const itemRect = item.getBoundingClientRect()
        const windowOffset = window.innerWidth <= 480
          ? 0
          : window.innerWidth <= 1024
          ? .19
          : .1

        const withoutPreviousOffset = window.innerWidth <= 480
          ? 0
          : window.innerWidth <= 1024
          ? window.innerWidth * 0.0675
          : 0

        const topOffset = previousActiveIndex === null && window.innerWidth < 480
          ? offsetHeight
          : 0

        const offset = previousActiveIndex === null
          ? window.innerWidth > 480
            ? containerRef.current!.offsetWidth / 6 + window.innerWidth * windowOffset
            : 0
          : withoutPreviousOffset

        item.style.left = targetLeft
          - itemRect.left
          - offset
          + (window.innerWidth <= 480 ? window.innerWidth * 0.055 : 0)
          + 'px'

        item.style.top = targetTop - (tempTop ?? itemRect.top) - topOffset + 'px'
        item.classList.add(styles.active)

      } else {
        item.style.left = '0px'
        item.style.top = '0px'
        item.classList.remove(styles.active)
      }
    })

    if (activeIndex !== null) {
      containerRef.current.classList.add(styles.active)
    } else {
      containerRef.current.classList.remove(styles.active)
    }

    if (window.innerWidth <= 480 && !activeItem) {
      containerRef.current!.style.gridTemplateRows = '1px 2fr'
    }
  }, [activeIndex, previousActiveIndex])

  return (
    <div className={styles.section + (activeIndex !== null ? ` ${styles.active}` : '')} ref={ref} id={block.type}>
      <div className={styles.waves}>
        <div className={styles.waveOne}>
          <Wave fill="url(#gradient)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: .2,
              points: 3
            }}
          >
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(30)">
                <stop offset="0%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#003D79" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.waveTwo}>
          <Wave fill="url(#gradient2)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude * .8,
              speed: .2,
              points: 6
            }}
          >
            <defs>
              <linearGradient id="gradient2" gradientTransform="rotate(50)">
                <stop offset="-20%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#033c75" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.waveThree}>
          <Wave fill="url(#gradient3)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: .2,
              points: 7
            }}
          >
            <defs>
              <linearGradient id="gradient3" gradientTransform="rotate(60)">
                <stop offset="0%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#003D79" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
      </div>
      <div className={styles.container}>
        <h2 className={styles.title}>{block.blockTitle}</h2>
        {block.techProtectTopDescription &&
        <ReactMarkdown className={styles.text}>{block.techProtectTopDescription}</ReactMarkdown>} 
        {block.techProtectTopButton &&
          <div className={styles.topButton}>
            <SharedTextButton button={block.techProtectTopButton} />
          </div>}
        <div className={styles.outerFeaturesContainer} ref={containerRef}>
          <div className={styles.activeTargetContainer}>
            <div
              className={styles.activeTarget}
              ref={activeTargetRef}
              style={{ width: '100%' }}
            ></div>
          </div>
          <div className={styles.features + (activeIndex !== null ? ` ${styles.active}` : '')}>
            {block.techProtectFeatureGrid.map((feature, i) => (
              <div
                className={styles.featureContainer + (activeIndex === i ? ` ${styles.active}` : '')}
                key={i}
              >
                <div
                  className={styles.feature}
                  onClick={() => activateIndex(activeIndex === i ? null : i)}
                >
                  <Image src={feature.icon} className={styles.icon} />
                  <p className={styles.featureText}>{feature.label}</p>
                  <div className={styles.content}>
                    <ReactMarkdown>{feature.text}</ReactMarkdown>
                    <div className={styles.contentButton} onClick={e => e.stopPropagation()}>
                      <SharedTextButton button={feature.sharedTextButton} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
       
        <div className={styles.footer}>
          <p className={styles.footerText}>{block.techProtectFooterText}</p>
          <SharedTextButton button={block.sharedTextButton} />
        </div>
      </div>
    </div>
  )
}