// extracted by mini-css-extract-plugin
export var active = "R_bd";
export var article = "R_d";
export var articleContainer = "R_dc";
export var articlesContainer = "R_db";
export var body = "R_c";
export var categories = "R_c4";
export var category = "R_c5";
export var container = "R_b";
export var date = "R_h";
export var featured = "R_c8";
export var featuredContainer = "R_c9";
export var header = "R_c3";
export var hero = "R_f";
export var icon = "R_bw";
export var image = "R_k";
export var inputContainer = "R_c6";
export var search = "R_cF";
export var searchInput = "R_c7";
export var section = "R_j";
export var title = "R_g";