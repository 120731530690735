// extracted by mini-css-extract-plugin
export var background = "bH_z";
export var backgroundContainer = "bH_fw";
export var bottomWave = "bH_df";
export var buttons = "bH_b6";
export var container = "bH_b";
export var content = "bH_bZ";
export var icon = "bH_bw";
export var iconContainer = "bH_bX";
export var middleWave = "bH_dd";
export var negativeTop = "bH_gY";
export var overlap = "bH_g0";
export var section = "bH_j";
export var selected = "bH_bg";
export var tab = "bH_dM";
export var tabText = "bH_dL";
export var title = "bH_g";
export var topWave = "bH_gZ";