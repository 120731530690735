// extracted by mini-css-extract-plugin
export var active = "bh_bd";
export var arrow = "bh_9";
export var button = "bh_cr";
export var buttonMobile = "bh_dZ";
export var column = "bh_cv";
export var columns = "bh_ct";
export var container = "bh_b";
export var description = "bh_dt";
export var disclaimer = "bh_c0";
export var disclaimerButton = "bh_dV";
export var disclaimerContainer = "bh_d1";
export var disclaimerText = "bh_d4";
export var fadeBottom = "bh_d3";
export var fadeTop = "bh_d2";
export var featureFooter = "bh_dX";
export var footer = "bh_ck";
export var footerMobile = "bh_dY";
export var heading = "bh_G";
export var highlight = "bh_dW";
export var icon = "bh_bw";
export var iconContainer = "bh_bX";
export var left = "bh_bL";
export var padding = "bh_dK";
export var popular = "bh_dJ";
export var prefix = "bh_dT";
export var recommend = "bh_dR";
export var recommended = "bh_dS";
export var right = "bh_bQ";
export var section = "bh_j";
export var show = "bh_L";
export var subHeading = "bh_H";
export var suffix = "bh_dG";
export var title = "bh_g";
export var waveContainer = "bh_d0";
export var waveOne = "bh_q";
export var waveThree = "bh_b8";
export var waveTwo = "bh_n";
export var waves = "bh_b7";